// PAA Colours
$tangerine: #ff6319;
$turquoise: #009aa6;
$lime: #b6bf00;
$eggplant: #6639b7;
$slate: #747f81;
$canary: #f5ec5a;
$turquoise-dark: #006b73;
$hover-highlight: #c6c6c6a0;

$font-size-base: 1rem * 0.8 !default;
$spacer: 1rem * 0.75 !default;
$line-height-base: 1.3;

$primary: $slate;
$secondary: $turquoise;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tangerine": $tangerine,
  "turquoise": $turquoise,
  "turquoise-dark": $turquoise-dark,
  "lime": $lime,
  "eggplant": $eggplant,
  "slate": $slate,
  "canary": $canary,
  "hover-highlight": $hover-highlight,
)