/*
    splash/background customizations
*/
#background-img {
    background-image: url(/plugins/arPaaPlugin/images/GR2004.0214.0012.jpg) !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.row {
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem !important;
}

.footer-tagline {
    position: absolute;
    bottom: 10px;
    right: 50px;
    width: 150px;
    height: 150px;

    @media (max-width: 768px) {
        display: none;
    }
}

/*
    default customizations
*/
body {
    color: #333;
}

.breadcrumb {
    padding: 0 15px;
}

#heading-label {
    padding: 15px 0 0 15px;
}

#h1-title {
    color: $primary;
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    display: flex;
}

a {
    text-decoration: none;
    color: $secondary;

    &:hover {
        color: $turquoise-dark;
        background-color: $hover-highlight;
        text-decoration: underline;
    }
}

.fa-paperclip {
    color: $canary;
}

.dropdown-item {
    &:hover {
        background-color: $turquoise;
        color: $canary;
    }
}

#wrapper {
    padding-top: 0 !important;
    padding-bottom: 25px !important;

    @media (min-width: 768px) {
        width: 95% !important;
    }

    @media (max-width: 1400px) {
        width: 80%;
    }
}

.w-0 {
    width: 100%;
}

.h-0 {
    height: 100%;
}

.ml-3 {
    margin-left: 1rem;
}

.pos-r {
    position: relative;
}

.alert {
    margin-top: 1rem;
    color: $primary;
    background-color: white;
}

/*
    Description customizations
*/
.paa-logo {
    width: 200px;

    a img {
        padding: 0px;
    }
}

.atom-section-header {
    a, div {
        padding: 0.5rem !important;
    }
}

.accordion-button {
    color: $eggplant !important;
    padding: 0.5rem;
}

.text-primary {
    color: $eggplant !important;
}

#treeview-tab, #treeview-list-tab, #treeview-search-tab {
    color: $turquoise;

    &:hover, &:focus, &:active {
        color: $canary;
        background-color: $turquoise-dark;
    }
}

.nav-link {
    color: $canary;

    &:hover, &:focus, &:active {
        color: $canary;
        background-color: $turquoise-dark;
    }
}

.atom-btn-secondary , .atom-btn-white, .page-link  {
    color: $secondary;

    &:hover, &:active, &:focus {
        color: $turquoise-dark;
        background-color: white;
    }

    i {
        color: $secondary;
    }
}

.actions li .btn, .actions .btn {
    color: $canary;

    &:hover, &:focus {
        color: $canary;
        background-color: $turquoise-dark;
    }
}

/*
    header customizations
*/
header {
    background-color: white;
}

#top-bar-container {
    width: 100%;
}

#top-bar {
    max-width: 1325px;
    margin: auto;
}

#header-row {
    position: relative;
    margin: 0 1rem;
    padding-top: 0 !important;
    height: 35px;
}

#header-navbar {
    background-color: $secondary;
    width: 100%;
}

#search-box-options {
    &:hover {
        background-color: $turquoise-dark;
    }

    i {
        color: $canary;
        font-size: 1rem;

        &:hover {
            color: $canary;
        }
    }
}

#search-box-options.dropdown-toggle::after {
    color: $canary;
    font-size: 1rem;

    &:hover {
        color: $canary;
    }
}

#search-box {
    height: 2rem;
}

.input-group .atom-btn-secondary {
    &:hover, &:focus, &:active {
        background-color: $turquoise-dark;
    }
}

.header-btn .dropdown .atom-btn-secondary {
    color: $canary;
    height: 2rem;
    font-size: 0.9rem;

    &:hover, &:focus, &:active {
        background-color: $turquoise-dark;
        color: $canary;
    }
}

li.breadcrumb-item.active {
    display: none;
}

ul .dropdown-menu {
    right: auto !important;
    left: auto !important;

    @media (max-width: 1450px) {
        right: 1rem !important;
    }
}

#site-title {
    a {
        color: $primary;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.5rem;

        &:hover {
            color: $primary;
            background-color: unset;
        }
    }
}

#site-logo-alberta {
    flex: 1;
    max-width: fit-content;

    a img {
        right: 0;
        position: absolute;
        height: 90%;
        width: auto;
    }
}

#site-title {
    flex: 1;

    h1{
        font-weight: 600;
        text-transform: uppercase;
        color: $primary;

        @media (max-width: 950px) {
            display: none;
        }

        a:hover {
            color: $slate;
        }
    }
}

.navbar-toggler, .navbar-toggler-icon {
    color: $canary;
    border-color: $canary;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: $canary;
        padding: 1px;
    }
}

/*
    homepage customizations
*/
#main-column {
    background-color: white;
    padding: 1rem;

    h1 {
        margin-left: 1rem;
    }
}

#homepage-content {
    p a {
        color: $secondary !important;
    }
}

#sidebar .card {
    h2 {
        color: $secondary !important;
        font-weight: 450;
    }

    .list-group {
        a {
            color: $secondary;

            &:hover {
                color: $turquoise-dark;
            }
        }

    }
}

/*
    carousel customizations
*/
#collapse-carousel .bg-secondary {
    background-color: $slate !important;

    div .btn {
        color: white;
        border: none;
    }
}

#atom-slider-bar {
    .ui-slider-handle {
        background-color: $eggplant;
    }
}